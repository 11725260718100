import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {
    Grid,
    TextField,
    Chip,
    makeStyles,
    Divider,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import FaceIcon from "@material-ui/icons/Face";

import {getGetHeader, getPostHeader} from "../../../Constants/requestHeaders";
import * as lang from "../../../Constants/strings";
import CustomModal from "../../../Components/Modals/CustomModal";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as actionCreator from "../../../actions/actions";
import TransferList from "../../Home/TechnicalTeam/TransferList";

const useStyle = makeStyles((theme) => ({
    autocomplete: {
        margin: theme.spacing(2)
    },
    chip: {
        margin: theme.spacing(2)
    },
    typo: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

const AddTechnicalTeamModal = (props) => {
    const {
        language,
        userData
    } = props;

    const classes = useStyle();

    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [teams, setTeams] = useState([]);

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [placeOfBusinessName, setPlaceOfBusinessName] = useState("");
    const [placeOfBusinessObject, setPlaceOfBusinessObject] = useState(null);
    const [placeOfBusinessNumberOfTransfers, setPlaceOfBusinessNumberOfTransfers] = useState({});

    const getUsers = async () => {
        try {
            const res = await axios.get('/api/team_users', getGetHeader());
            // Remove the current user form the list
            const tmp = [...res.data];
            const index = tmp.findIndex((e) => e.USER_ID === userData.id);
            if (index !== -1) tmp.splice(index, 1);
            setUsers(tmp);
        } catch (e) {
            setUsers([]);
        }
    };

    const getTeamsNames = async () => {
        try {
            const res = await axios.get('/api/teams', {
                ...getGetHeader(),
                params: {
                    accountId: userData.id,
                }
            });

            let formated = [];
            let placeOfBusinesses = [];

            for (const c of res.data) {
                placeOfBusinesses.push(...c.placeOfBusinesses.map(x => x.name));
            }

            placeOfBusinesses = [...new Set([...placeOfBusinesses])];

            for (const c of placeOfBusinesses) {
                let list = [];
                for (const e of res.data) {
                    let pIndex = e.placeOfBusinesses.findIndex(x => x.name === c);

                    list.push({
                        id: pIndex !== -1 ? e.placeOfBusinesses[pIndex].id : null,
                        companyId: e.id,
                        companyName: e.name,
                    });
                }

                formated.push({
                    name: c,
                    data: list
                });
            }

            setTeams(formated);
        } catch (e) {
            setTeams([]);
        }
    };

    const checkIfUserHasTeam = async () => {
        try {
            const res = await axios.get('/api/user_team', getGetHeader());
            if (res.data.isTechnicalUser) {
                setOpen(!res.data.hasTeam);
            } else {
                setOpen(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const close = () => setOpen(false);

    const createTeam = async () => {
        if (placeOfBusinessName.length < 1) return;

        // Get the external place of business
        const inventoryInfo = teams[teams.findIndex(x => x.name === placeOfBusinessName)];

        try {
            const userIds = [userData.id];
            for (const u of selectedUsers) {
                userIds.push(u.USER_ID);
            }

            await axios.post('/api/teams',
                {
                    name: placeOfBusinessName,
                    userIds,
                    inventoryInfo
                },
                getPostHeader()
            );
        } catch (e) {
            console.log(e);
        } finally {
            close();
        }
    }

    const deleteSelectedUser = async (index) => {
        const tmp = [...selectedUsers];
        tmp.splice(index, 1);
        setSelectedUsers(tmp);
    };

    const countNoOfTransfers = () => {
        const keys = Object.keys(placeOfBusinessNumberOfTransfers);
        let no = 0;

        for(const key of keys) {
            no += placeOfBusinessNumberOfTransfers[key];
        }
        return no;
    };

    const setPlaceOfBusinessTransferNumber = useCallback((n, id) => {
        let tmp = { ...placeOfBusinessNumberOfTransfers };
        tmp[id] = n;
        setPlaceOfBusinessNumberOfTransfers(tmp);
    }, [placeOfBusinessNumberOfTransfers]);

    useEffect(() => {
        checkIfUserHasTeam().then(() => {});
        getUsers().then(() => {});
        getTeamsNames().then(() => {});
    }, []);

    console.log(placeOfBusinessNumberOfTransfers);

    return (
        <CustomModal
            open={open}
            close={() => alert(lang.CAN_NOT_CLOSE_SELECTING_TEAM_MODAL[language])}
            fullScreen={true}
            title={lang.ADD_TECHNICAL_TEAM[language]}
            content={
                <Grid container spacing={2} justifyCenter="center">
                    <FormControl
                        fullWidth
                        variant="outlined"
                        className={classes.autocomplete}
                    >
                        <InputLabel id="car-number-input">{lang.CAR_NUMBER[language]}</InputLabel>
                        <Select
                            lable={lang.CAR_NUMBER[language]}
                            variant="outlined"
                            inputProps={{id: 'car-number-input'}}
                            fullWidth
                            value={placeOfBusinessName}
                            onChange={(e) => {
                                setPlaceOfBusinessName(e.target.value);
                                const index = teams.findIndex(x => x.name === e.target.value);
                                if (index !== -1) {
                                    setPlaceOfBusinessObject(teams[index]);
                                }
                                setPlaceOfBusinessNumberOfTransfers({});
                            }}
                        >
                            {
                                teams.map((x, index) => <MenuItem key={`palce_of_business_menu_item_${index}`}
                                                                  value={x.name}>{x.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <Grid item xs={12}>
                    {
                        placeOfBusinessObject && placeOfBusinessObject.data.map((x, index) => <TransferList
                            key={`transfer-list-${index}`}
                            id={x.id}
                            companyName={x.companyName}
                            companyId={x.companyId}
                            userId={userData.id}
                            language={language}
                            onNumberOfItemsChange={(n) => setPlaceOfBusinessTransferNumber(n, x.id)}
                        />)
                    }
                    </Grid>
                    <Autocomplete
                        id="combo-box-users"
                        options={users}
                        className={classes.autocomplete}
                        getOptionLabel={(option) => option.USERNAME}
                        onChange={(t, v) => {
                            if (v)
                                setSelectedUsers([...new Set([...selectedUsers, v])]);
                        }}
                        fullWidth
                        renderInput={
                            (params) =>
                                <TextField
                                    {...params}
                                    label={lang.USERS[language]}

                                    variant="outlined"
                                />
                        }
                    />
                    <Grid xs={12}>
                        <Divider fullWidth/>
                        <Typography variant="h6" className={classes.typo}>
                            {lang.TEAM_MEMBERS[language]}
                        </Typography>
                    </Grid>
                    <Chip
                        key={`current_user`}
                        className={classes.chip}
                        icon={<FaceIcon/>}
                        label={userData ? userData.username : ""}
                        color="primary"
                    />
                    {
                        selectedUsers.map((u, i) => (
                            <Chip
                                key={`selected_user_${i}`}
                                className={classes.chip}
                                icon={<FaceIcon/>}
                                label={u.USERNAME}
                                onDelete={() => deleteSelectedUser(i)}
                                color="secondary"
                            />
                        ))
                    }

                </Grid>
            }
            language={language}
            validate={placeOfBusinessName && placeOfBusinessName.length > 0 && countNoOfTransfers() === 0}
            execute={() => createTeam()}
        />
    )
};

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        isAuthenticated: state.config.isAuthenticated,
        permissions: state.auth.permissions,
        userData: state.auth.userData,
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTechnicalTeamModal);