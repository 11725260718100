import { useState, useEffect } from "react";
import axios from "axios";
import CustomModal from "../../../Components/Modals/CustomModal";
import SearchItemByName from "../../../Components/TechnicalUser/SearchItemByName";
import SearchItemByBarCode from "../../../Components/TechnicalUser/SearchItemByBarCode";
import * as requestHeaders from "../../../Constants/requestHeaders";
import * as lang from "../../../Constants/strings";
import {Divider, Grid, Typography} from "@material-ui/core";
import * as reqHeaders from "../../../Constants/requestHeaders";
import Item from "../../../Components/TechnicalUser/Item";

const RegisterInventory = (props) => {
    const {
        open,
        onClose,
        userId,
        contractorId,
        taskId,
        type,
        language
    } = props;

    const [inv, setInv] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [placeOfBusiness, setPlaceOfBusiness] = useState(null);
    const [items, setItems] = useState([]);

    const fetchTaskExternalCompany = async () => {
        try {
            const res = await axios.get("/api/external/companies/" + contractorId, requestHeaders.getGetHeader())
            setCompanyId(res.data.data);
        } catch (e) {
            console.error(e);
        }
    }

    const fetchTeamInventory = async () => {
        try {
            const res = await axios.get("/api/team/inventory", {
                ...reqHeaders.getGetHeader(), params: {
                    id: userId
                }
            });

            setInv(res.data);
        } catch (e) {
            console.log(e);
        }
    }

    const saveTaskItems = async () => {
        try {
            await axios.post(`/api/task/basket`,
                {
                    id: taskId,
                    userId: userId,
                    items: items,
                    type: type
                },
                reqHeaders.getPostHeader()
            );
            onClose();
        } catch (e) {
            console.error(e);
        }
    }

    const updateItem = (v, i) => {
        let tmp = [...items];
        tmp[i] = v;
        setItems(tmp);
    }

    const onRemove = (i) => {
        let tmp = items;
        tmp.splice(i, 1);
        setItems([...tmp]);
    }

    const onAdd = (v) => {
        setItems((prev) => {
            const index = prev.findIndex(x => x.productId === v.productId);
            if(index === -1) {
                return [...prev, { ...v, value: 1 }];
            } else {
                return prev;
            }
        });
    }

    useEffect(() => {
        if (contractorId) fetchTaskExternalCompany().then(() => { });
    }, [contractorId])

    useEffect(() => {
        if (inv && companyId) {
            const index = inv.data.findIndex((x) => x.companyId === companyId);
            if (index !== -1) setPlaceOfBusiness(inv.data[index].id);
        }
    }, [inv, companyId]);

    useEffect(() => {
        fetchTeamInventory().then(() => { });
    }, []);

    return (
        <CustomModal
            fullScreen
            fullWidth
            form={false}
            open={open}
            close={() => onClose()}
            title={lang.REGISTER_ITEMS_TO_TASK[language]}
            content={<Grid conatiner>
                {
                    placeOfBusiness && userId &&
                    <Grid item xs={12}>
                        <SearchItemByBarCode
                            placeOfBusinessId={placeOfBusiness}
                            userId={userId}
                            language={language}
                            setValue={(item) => onAdd(item)}
                        />
                    </Grid>
                }
                {
                    placeOfBusiness && userId &&
                    <Grid item xs={12} style={{ marginTop: "30px"}}>
                        <SearchItemByName
                            placeOfBusinessId={placeOfBusiness}
                            userId={userId}
                            language={language}
                            setValue={(item) => onAdd(item)}
                        />
                    </Grid>
                }
                {
                    (!placeOfBusiness || !userId) &&
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Typography variant="body2" color="textSecondary">
                            Compania ticket-ului nu are setat un inventar extern!
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Contacteaza administratorul pentru mai multe informati!
                        </Typography>
                    </Grid>
                }
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <Divider />
                </Grid>
                {
                    items.map((x, index) => <Grid key={`item_${index}`} item xs={12} style={{ marginTop: "10px" }}>
                        <Item
                            key={`item_by_index_${index}`}
                            item={x}
                            setItem={(x) => updateItem(x, index)}
                            remove={() => onRemove(index)}
                        />
                    </Grid>)
                }
            </Grid>}
            execute={() => saveTaskItems()}
            language={language}
            validate={items.length > 0 && items.findIndex(x => Number(x.value) === 0) === -1}
        />
    )
}

export default RegisterInventory;
